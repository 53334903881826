<div class="main-container">
    <div class="content-container">
        <div class="information-container">
            <div class="button-container">
                <p class="question-text">{{ error }}</p>
                <p class="question-text">{{ errorInfo }}</p>
            </div>
        </div>
    </div>
</div>
