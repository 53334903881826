import { Routes } from '@angular/router';
import {InformationPageComponent} from "./components/information-page/information-page.component";
import {LastPageComponent} from "./components/last-page/last-page.component";
import {QuestionPageComponent} from "./components/question-page/question-page.component";

export const routes: Routes = [
    {path: "last-page", component: LastPageComponent},
    {path: "question-page", component: QuestionPageComponent},
    { path: ':hashkey', component: InformationPageComponent },
    {path: "**", component: InformationPageComponent},
];
