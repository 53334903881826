import {Component, Input} from '@angular/core';
import {MatSlider, MatSliderThumb} from "@angular/material/slider";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatButton} from "@angular/material/button";
import {ThemeModel} from "../../models/theme.model";
import {AsyncPipe, NgStyle} from "@angular/common";
import {ThemeService} from "../../services/theme.service";



@Component({
  selector: 'app-progress-bar',
  standalone: true,
    imports: [
        MatSlider,
        MatSliderThumb,
        MatProgressBar,
        MatButton,
        AsyncPipe,
        NgStyle
    ],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
  @Input() amountOfQuestions: number = 0;
  @Input() currentQuestion: number = 0;
    @Input() themeObject!: ThemeModel | undefined;
    constructor(
        public themeService: ThemeService
    ) {}

}
