<div class="scale-container">
    <div class="scale-labels">
        <span class="left-label" [ngStyle]="{ 'color': (themeService.theme$ | async)?.textColor }">{{ leftLabel }}</span>
        <span class="right-label" [ngStyle]="{ 'color': (themeService.theme$ | async)?.textColor }">{{ rightLabel }}</span>
    </div>
    <div class="scale-options">
        @for(option of fixedOptions; track option; let index = $index) {
            <div class="scale-item">
                <input type="radio" id="scale-{{option}}" name="scale" value="{{option}}" (click)="onClick.emit(option)">
                <label for="scale-{{option}}" [ngStyle]="{ 'background-color': (themeService.theme$ | async)?.buttonColor, 'color': (themeService.theme$ | async)?.textColor }">
                    {{option}}
                </label>
            </div>
        }
    </div>
</div>
