<div class="last-page">

    <div class="information-container">
        <div class="ag-container">
            @if(mailObject?.pictureHashkey){
                <img class="company-logo" ngSrc="../../../assets/uploads/{{mailObject?.pictureHashkey}}" alt="Company logotype" height="1459" width="2292" priority>
            }
            @if(!mailObject?.pictureHashkey){
                <img class="company-logo" ngSrc="../../../assets/ArcticGroupLogo.png" alt="Arctic Group Logo" height="1459" width="2292" priority>
            }
        </div>
        <p class="information-text">Tack för din medverkan!</p>
    </div>

</div>
