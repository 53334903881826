<div class="dynamic-button">
    @switch (questionData.type) {

        @case ('TrueFalse'){
            @for (option of questionData.options; track option; let index = $index){
                <button mat-raised-button [ngStyle]="{'background-color': (themeService.theme$ | async)?.buttonColor,'color': (themeService.theme$ | async)?.textColor}" (click)="onClick.emit(option)" [disabled]="disabled" [routerLink]="questionData.routerLink != undefined ? questionData.routerLink[index] : null">{{ option.text }}</button>
            }
        }

        @case ('FreeText') {
            <mat-form-field class="text-area">
                <mat-label>Skriv ditt svar här</mat-label>
                <textarea matInput #textarea placeholder="..."></textarea>
            </mat-form-field>
            <button mat-raised-button [ngStyle]="style"  [ngStyle]="{ 'background-color': (themeService.theme$ | async)?.buttonColor}" [ngClass]="{'text-button-enabled': !disabled && textarea.value, 'text-button-disabled': disabled || !textarea.value}" (click)="onClick.emit(textarea.value); textarea.value = ''" [disabled]="disabled || !textarea.value"><mat-icon class="mat-icon">arrow_forward_ios_new</mat-icon></button>
        }

        @case ('Scale') {
            <app-scale-button
                    [leftLabel]="leftLabel"
                    [rightLabel]="rightLabel"
                    [themeObject]="themeObject"
                    (onClick)="onClick.emit($event)">
            </app-scale-button>
        }

    }
</div>
