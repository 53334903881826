import {Component} from '@angular/core';


@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {
    error: string = "Här var det tomt!";
    errorInfo: string = "Sidan kunde inte hittas, kontakta oss på refhub@arcticgroup.se om du vill veta mer om tjänsten.";
}
