import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {AsyncPipe, NgClass, NgOptimizedImage, NgStyle} from "@angular/common";
import {MatButton, MatFabButton} from "@angular/material/button";
import {DynamicButtonComponent} from "../dynamic-button/dynamic-button.component";
import {QuestionModel} from "../../models/question.model";
import {BackgroundComponent} from "../background/background.component";
import {QuestionPageComponent} from "../question-page/question-page.component";
import {LastPageComponent} from "../last-page/last-page.component";
import {ErrorPageComponent} from "../error-page/error-page.component";
import {MatIcon} from "@angular/material/icon";
import {OptionModel} from "../../models/option.model";
import {catchError, of, Subscription} from "rxjs";
import { ActivatedRoute } from '@angular/router';
import {FormService} from "../../services/form.service";
import {ThemeService} from "../../services/theme.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {Mail} from "../../models/mail.model";
import {ThemeModel} from "../../models/theme.model";

@Component({
    selector: 'app-information-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        MatButton,
        DynamicButtonComponent,
        NgStyle,
        AsyncPipe,
        BackgroundComponent,
        QuestionPageComponent,
        LastPageComponent,
        ErrorPageComponent,
        MatIcon,
        MatFabButton,
        MatProgressSpinnerModule,
        NgClass
    ],
    templateUrl: './information-page.component.html',
    styleUrl: './information-page.component.scss'
})
export class InformationPageComponent implements OnInit, OnDestroy {


    @ViewChild('backgroundComponent', { read: ViewContainerRef }) container!: ViewContainerRef;

    currentPageIndex: number = 0;
   // agMail: string = "Vill du veta mer om eller är intresserad av RefHub? Kontakta oss på refhub@arcticgroup.se";
    loading: boolean = true;
    mailInfoLoaded: boolean = false;
    @Input() hashkey = '';
    @Input() themeObject: ThemeModel | undefined;
    animationClass: string = 'animate__animated animate__backInUp';

    subscriptions: Subscription = new Subscription();
    @Input() mailObject: Mail | undefined;

    constructor(
        private route: ActivatedRoute,
        private formService: FormService,
        private cfr: ComponentFactoryResolver,
        public themeService: ThemeService
    ) {}

    ngOnInit() {
        setTimeout(() => {
            this.loadBackground()

        },500)
        this.route.params.subscribe(params => {
            this.hashkey = params['hashkey'];
            if (this.hashkey) {
                this.loadMailInfo();
                const themeId = this.mailObject?.theme.id as number | undefined;
            }else{
                this.currentPageIndex = 3;
                this.loading = false;
            }
        });

    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    loadMailInfo() {
        this.subscriptions.add(
            this.formService.getMailByHashkey(this.hashkey).pipe(
                catchError(error => {
                    this.currentPageIndex = 3;
                    this.loading = false;
                    return of(null);
                })
            ).subscribe((mailObject: Mail | null) => {
                if (mailObject) {
                    this.mailObject = mailObject;
                    this.checkIfFormIsAlreadyAnswered()
                        this.themeService.setTheme(mailObject.theme);
                } else {
                    if (this.currentPageIndex !== 3) {
                        this.currentPageIndex = 2;
                        this.loading = false;
                    }
                }
            })
        );
    }




    checkIfFormIsAlreadyAnswered(){
        this.subscriptions.add(
            this.formService.isFormCompleted(this.hashkey).subscribe((response: string) => {
                const boolValue = (response =="true");
                this.loading = false;
                if(!boolValue) {
                    this.currentPageIndex = 0;
                    return
                };
                this.currentPageIndex = 2;
            })
        )
    }

    async loadBackground() {
        if(!this.container)return;
        this.container.clear();
        const { BackgroundComponent } = await import('../background/background.component');
        this.container.createComponent(this.cfr.resolveComponentFactory(BackgroundComponent));
    }

    questionInfo: QuestionModel = {
        id: 1,
        text: 'Vi skulle uppskatta om du kunde svara på några frågor om hur du upplevt samarbetet med oss under arbetets gång.',
        type: 'TrueFalse',
        options: [{id: 2, text: 'Nej tack'}, {id: 1, text: 'Till frågorna'}],
        createdAt: new Date()
    }

    navigate(option: OptionModel | string) {
        if(typeof option == 'string')
            return;

        if(option.id == 1){
            this.currentPageIndex = option.id;
            this.resetAnimation()
            return;
        }
        this.currentPageIndex = 2;
    }

    handleDefaultError() {
        this.currentPageIndex = 0;
        return "404, redirecting";
    }
    resetAnimation() {
        this.animationClass = '';
        setTimeout(() => {
            this.animationClass = 'animate__animated animate__zoomIn';
        }, 10);
    }

}
