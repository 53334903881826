import {Component, HostListener, OnInit} from '@angular/core';
import { Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'frontend';

    constructor(private router: Router) {
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        if (this.isBrowser() && window.sessionStorage) {
            sessionStorage.setItem('shouldReload', 'true');
        }
    }

    ngOnInit() {
        if (this.isBrowser() && window.sessionStorage) {
            const shouldReload = sessionStorage.getItem('shouldReload');
            if (shouldReload === 'true') {
                sessionStorage.removeItem('shouldReload');
                location.reload();
            }
            sessionStorage.setItem('shouldReload', 'false');
        }
    }

    private isBrowser(): boolean {
        return typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined';
    }
}
