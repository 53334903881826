import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AsyncPipe, NgOptimizedImage, NgStyle} from "@angular/common";
import {FormService} from "../../services/form.service";
import {FormModel} from "../../models/form.model";
import {DynamicButtonComponent} from "../dynamic-button/dynamic-button.component";
import {ProgressBarComponent} from "../progress-bar/progress-bar.component";
import {Observable, Subscription} from "rxjs";
import {BackgroundComponent} from "../background/background.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {OptionModel} from "../../models/option.model";
import {AnswerRequestModel} from "../../models/answerRequest.model";
import {Mail} from "../../models/mail.model";
import {ThemeModel} from "../../models/theme.model";
import {ThemeService} from "../../services/theme.service";

@Component({
    selector: 'app-question-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        DynamicButtonComponent,
        ProgressBarComponent,
        AsyncPipe,
        NgStyle,
        BackgroundComponent,
        MatProgressSpinnerModule,
        MatFabButton,
        MatIcon
    ],
    templateUrl: './question-page.component.html',
    styleUrl: './question-page.component.scss'
})
export class QuestionPageComponent implements OnInit, OnDestroy {
    @Input() mailObject: Mail | undefined;
    @Input() themeObject: ThemeModel | undefined;
    @Input() hashkey = '';

    @Output() changePageEvent = new EventEmitter<number>();

    form$!: Observable<FormModel>;
    questionNr: number = 0;
    totalNumberOfQuestions: number = 0;
    formId: number = 0;
    mail: string = '';
    loading: boolean = true;

    subscriptions: Subscription = new Subscription();

    constructor(
        private formService: FormService,
        public themeService: ThemeService
    ) {}


    ngOnInit() {
        this.formId = this.mailObject?.form.id || 0;
        this.mail = this.mailObject?.mail || '';
        this.form$ = this.formService.getFormById(this.formId)
        this.updateTotalNumberOfQuestions()
        this.checkForUnfinishedForm()
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    updateTotalNumberOfQuestions() {
        this.subscriptions.add(
            this.form$.subscribe((form) => {
                this.totalNumberOfQuestions = (form.questions.length - 1)
            })
        )
    }

    checkForUnfinishedForm() {
        this.subscriptions.add(
            this.formService.getAnsweredQuestionIds(this.formId, this.mail).subscribe((ids: number[]) => {
                if(ids.length != 0) {
                    this.goToCurrentQuestion(ids)
                    return;
                }
                this.loading = false;
            })
        )
    }

    goToCurrentQuestion(ids: number[]) {
        this.form$.subscribe((form) => {
            const firstNotAnsweredQuestionIndex = form.questions.findIndex(question => !ids.includes(question.id));

            if(firstNotAnsweredQuestionIndex !== -1) this.questionNr = firstNotAnsweredQuestionIndex

            this.loading = false;
        })
    }

    answered(questionNr: number, option: OptionModel | string){

        this.form$.subscribe((form) => {
            const currentQuestion = form.questions[questionNr];
            const questionId = currentQuestion.id;


            const answerRequest: AnswerRequestModel = {
                formId: this.formId,
                questionId: questionId,
                mail: this.mail,
                answer: typeof option == "string" ? option : option.text,
                optionId: typeof option == "string" ? null : option.id
            }

            this.subscriptions.add(
                this.formService.putAnswer(answerRequest).subscribe((response) => console.log(response))
            )

            if(questionNr == this.totalNumberOfQuestions){
                this.formService.markFormAsCompleted(this.hashkey).subscribe()
                this.changePageEvent.emit(2);
                return;
            }

            this.questionNr++;
        })
    }

    back(questionNr: number){
        if(questionNr == 0){
            this.changePageEvent.emit(0);
            return;
        }

        this.questionNr--
    }

}
