import {Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {AsyncPipe, NgClass, NgStyle} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {ScaleButtonComponent} from "../scale-button/scale-button.component";
import {QuestionModel} from "../../models/question.model";
import {OptionModel} from "../../models/option.model";
import {MatIcon} from "@angular/material/icon";
import {ThemeModel} from "../../models/theme.model";
import {ThemeService} from "../../services/theme.service";
import {FormService} from "../../services/form.service";
import {AnswerRequestModel} from "../../models/answerRequest.model";
import {catchError, of} from "rxjs";

/**
 * Custom knapp som vi kan använda vid behov
 * ---
 * Just nu finns följande taggar du kan använda:
 *
 * questionData: QuestionModel, denna är obligatorisk och måste finnas
 *
 * style: {'key': string}, så t.ex.
 * ```[style]="{'width': '20vw', 'height': '20vh'}```"
 *
 * disabled: boolean
 */
@Component({
  selector: 'app-dynamic-button',
  standalone: true,
    imports: [
        MatButton,
        NgStyle,
        MatLabel,
        MatInput,
        MatRadioGroup,
        MatRadioButton,
        RouterLink,
        ScaleButtonComponent,
        MatFormField,
        NgClass,
        MatIcon,
        NgStyle,
        AsyncPipe
    ],
  templateUrl: './dynamic-button.component.html',
  styleUrl: './dynamic-button.component.scss'
})
export class DynamicButtonComponent {

    @Input() style?: {[key: string]: string};
    @Input() disabled: boolean = false;
    @Input() themeObject: ThemeModel | undefined;
    @Input() questionData!: QuestionModel;
    @Output() onClick = new EventEmitter<OptionModel | string>();
    @Input() formId: number | undefined
    @Input() email: string = ''

    leftLabel: string = '';
    rightLabel: string = '';

    pendingContent: AnswerRequestModel | undefined

    constructor(
        public themeService: ThemeService,
        public formService: FormService
    ) {}

    @ViewChild('textarea') textarea!: ElementRef;

    ngOnInit() {
        if (this.questionData.type === 'Scale' && this.questionData.options.length > 0) {
            const labels = this.questionData.options[0].text.split(';');
            this.leftLabel = labels[0];
            this.rightLabel = labels[1];
        }
    }

    // Function to check if the question data was changed.
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['questionData']) {
            const previousValue = changes['questionData'].previousValue;
            const currentValue = changes['questionData'].currentValue;

            if (previousValue?.id !== currentValue.id) {
                if (currentValue.type == 'FreeText') {
                    this.resetInput()

                    if (this.formId != undefined && this.formId != 0) {
                        this.pendingContent = {
                            formId: this.formId,
                            questionId: currentValue.id,
                            mail: this.email,
                            answer: '',
                            optionId: null
                        }
                    }
                }
            }
        }
    }

    ngAfterViewChecked() {
        if (this.pendingContent && this.textarea?.nativeElement) this.loadOldAnswer()
    }

    resetInput(): void {
        if (this.textarea?.nativeElement && this.textarea.nativeElement.value) this.textarea.nativeElement.value = ''
    }

    loadOldAnswer(): void {
        if (this.pendingContent) {
            const content: AnswerRequestModel = this.pendingContent
            this.pendingContent = undefined

            this.formService.getAnswer(content).pipe(
                catchError((error) => {
                    console.error('Error fetching answer:', error);
                    return of({ answer: '' });
                })
            ).subscribe((data) => {
                this.textarea.nativeElement.value = data.answer
            })
        }
    }

}
