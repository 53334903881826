import { Injectable } from '@angular/core';
import { FormModel } from "../models/form.model";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import { environment } from "../../environments/environment";
import {AnswerRequestModel} from "../models/answerRequest.model";
import {Mail} from "../models/mail.model";
import {ThemeModel} from "../models/theme.model";

@Injectable({
  providedIn: 'root',
})
export class FormService {

    constructor(private http: HttpClient) {
    }

    getFormById(formId: number): Observable<FormModel> {
        return this.http.get<FormModel>(environment.apiUrl + '/formById/' + formId)
    }

    putAnswer(answerRequest: AnswerRequestModel): Observable<string>{
        return this.http.put<string>(environment.apiUrl + '/answer', answerRequest, { responseType: "text" as "json" })
    }

    getAnswer(answerRequest: AnswerRequestModel): Observable<AnswerRequestModel> {
        return this.http.get<any>(`${environment.apiUrl}/answer?formId=${answerRequest.formId}&questionId=${answerRequest.questionId}&mail=${answerRequest.mail}`).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('Something went wrong; please try again later.');
        return throwError('Something went wrong; please try again later.');
    }

    getAnsweredQuestionIds(formId: number, mail: string): Observable<number[]>{
        return this.http.get<number[]>(environment.apiUrl + `/answeredQuestionIds/${formId}/${mail}`, { responseType: "text" as "json" })
    }

    markFormAsCompleted(hashkey: string): Observable<string>{
        return this.http.put<string>(environment.apiUrl + `/markFormAsCompletedByHashkey/${hashkey}`, { responseType: "text" as "json" })
    }

    isFormCompleted(hashkey: string): Observable<string> {
        return this.http.get<string>(environment.apiUrl + `/isFormCompletedByHashkey/${hashkey}`, { responseType: "text" as "json" })
    }

    getMailByHashkey(hashkey: string) {
        return this.http.get<Mail>(environment.apiUrl + '/mailByHashkey/' + hashkey)
    }

    getThemeByMail(themeId: number){
        return this.http.get<ThemeModel>(environment.apiUrl + '/theme/' + themeId)
    }

}
