import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OptionModel} from "../../models/option.model";
import {ThemeModel} from "../../models/theme.model";
import {AsyncPipe, NgStyle} from "@angular/common";
import {ThemeService} from "../../services/theme.service";

@Component({
    selector: 'app-scale-button',
    standalone: true,
    imports: [
        AsyncPipe,
        NgStyle
    ],
    templateUrl: './scale-button.component.html',
    styleUrl: './scale-button.component.scss'
})
export class ScaleButtonComponent {
    constructor(
        public themeService: ThemeService
    ) {}
    @Output() onClick = new EventEmitter<string>();
    @Input() options: OptionModel[] | null = []
    @Input() leftLabel: string = '';
    @Input() rightLabel: string = '';
    @Input() themeObject: ThemeModel | undefined;

    fixedOptions: string[] = ['1','2','3','4','5'];
}

