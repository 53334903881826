<div class="main-container" [ngStyle]="{ 'background-color': (themeService.theme$ | async)?.backgroundColor,'color': (themeService.theme$ | async)?.textColor  }">

    <div class="content-container" [ngClass]="animationClass">
        @if(!loading ) {
            @switch (currentPageIndex){
                <!-- case 0 är alltså information-page/första sidan. Laddas in om utskick med hashkey finns -->
                @case (0) {
                    <div class="information-container">
                        <div class="ag-container">
                            @if(mailObject?.pictureHashkey){
                                <img class="company-logo" ngSrc="../../../assets/uploads/{{mailObject?.pictureHashkey}}" alt="Company Logotype" height="1459" width="2292" priority>
                            }
                            @if(!mailObject?.pictureHashkey){
                                <img class="company-logo" ngSrc="../../../assets/ArcticGroupLogo.png" alt="Arctic Group Logo" height="1459" width="2292" priority>
                            }
                        </div>
                        <p class="information-text">Utvärdering av slutfört projekt</p>
                        <div class="button-container">
                            <p class="question-text">{{ questionInfo.text }}</p>
                            <app-dynamic-button [questionData]="questionInfo" (onClick)="navigate($event)" [style]="{'width': '200px', 'height': '75px', 'font-size': '25px'}" [themeObject]="themeObject"></app-dynamic-button>
                        </div>
                    </div>
                }
                @case (1) {
                    <app-question-page (changePageEvent)="currentPageIndex = $event" [mailObject]="mailObject" [hashkey]="hashkey" [themeObject]="themeObject"></app-question-page>
                }
                @case (2) {
                    <app-last-page [mailObject]="mailObject"></app-last-page>
                }
                @case (3) {
                    <div class="information-container">
                        <div class="ag-container">
                            <img class="company-logo" ngSrc="../../../assets/ArcticGroupLogo.png" alt="Arctic Group Logo" height="1459" width="2292" priority>
                        </div>
                        <div class="error-container">
                            <app-error-page></app-error-page>
                        </div>
                    </div>
                }
                @default {
                    {{handleDefaultError()}}
                }
            }
        }
        @else {
            <div class="information-container">
                <div class="ag-container">
                    <img class="company-logo" ngSrc="../../../assets/ArcticGroupLogo.png" alt="Arctic Group Logo" height="1459" width="2292" priority>
                </div>
                <mat-spinner></mat-spinner>

            </div>
        }
    </div>

    <div class="background-container">
        <ng-template #backgroundComponent></ng-template>
    </div>
</div>
