import {Component, Input} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {AsyncPipe, NgOptimizedImage, NgStyle} from "@angular/common";
import {RouterLink} from "@angular/router";
import {BackgroundComponent} from "../background/background.component";
import {DynamicButtonComponent} from "../dynamic-button/dynamic-button.component";
import {Mail} from "../../models/mail.model";

@Component({
  selector: 'app-last-page',
  standalone: true,
    imports: [
        MatButton,
        NgOptimizedImage,
        RouterLink,
        AsyncPipe,
        NgStyle,
        BackgroundComponent,
        DynamicButtonComponent
    ],
  templateUrl: './last-page.component.html',
  styleUrl: './last-page.component.scss'
})
export class LastPageComponent {

    constructor() {}
@Input() mailObject: Mail | undefined;

}
