<div class="question-page">

    @if(!loading){

        <div class="back-button-container">
            <button mat-fab color="primary" aria-label="Example icon button with a delete icon" (click)="back(questionNr)" [ngStyle]="{ 'background-color': (themeService.theme$ | async)?.buttonColor,'color': (themeService.theme$ | async)?.textColor}">
                <mat-icon>arrow_back_ios_new</mat-icon>
            </button>
        </div>

        <div class="main-container">
            <div class="header-container">
                @if(mailObject?.pictureHashkey){
                    <img class="company-logo" ngSrc="../../../assets/uploads/{{mailObject?.pictureHashkey}}" alt="Company logotype" height="1459" width="2292" priority>
                }
                @if(!mailObject?.pictureHashkey){
                    <img class="company-logo" ngSrc="../../../assets/ArcticGroupLogo.png" alt="Arctic Group Logo" height="1459" width="2292" priority>
                }
            </div>

            @if(form$ | async; as formData) {
                <div class="question-container">

                    <p class="questionText">{{formData.questions[questionNr].text}}</p>

                    <div class="button-container">
                        <app-dynamic-button [email]="mail" [formId]="formId" [questionData]="formData.questions[questionNr]" [style]="{'background-color': '#ee500d'}" (onClick)="answered(questionNr, $event)"></app-dynamic-button>
                    </div>

                </div>

                <div class="progressBar-container">
                    <app-progress-bar [amountOfQuestions]="formData.questions.length" [currentQuestion]="questionNr" [themeObject]="themeObject"></app-progress-bar>
                </div>
            }
            @else {
                <div class="loading-spinner">
                    <mat-spinner></mat-spinner>
                </div>
            }

        </div>
    }
    @else {
        <div class="loading-spinner">
            <mat-spinner></mat-spinner>
        </div>
    }

</div>
